import axios from 'axios'
import { isNavigationFailure, NavigationFailureType, router } from "@/js/routes.js"
import { store } from '../vuex/store'
import queryString from 'query-string'
import { setLanguageCookie } from './helpers'

// Redirect the user to entrance
export function redirectToEntrance(params) {
	return router.push({
		name: 'sign-in',
		hash: params?.urlHash
	}).then(() => {
		store.commit('setLoggedIn', false) // Log out UI
	}).catch(failure => {
		if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
			console.log("Stopped navigating from " + failure.from.path + " to " + failure.to.path)
		}
	})
}

export async function logout(params) {
	store.commit("setLoaderContent", "default")
			
	await axios.get('/api/user-side/logoutUser')

	// This timeout is needed to wait for all route transitions to complete
	setTimeout(() => { store.commit("setLoaderContent", false) }, 1000)

	return redirectToEntrance({
		urlHash: params?.urlHash
	})
}


export function switchLanguage(language) {
	// Do nothing it's an attempt to switch to the current language
	if(store.state.currentLanguage == language){
		return; 
	}

	store.commit("setLoaderContent", "default")

	setTimeout(async () => { // Delay execution to let transitions get in force
		// Save new language to cookies
		setLanguageCookie(language)

		// Save language to server if needed and reload the page
		if(store.state.loggedIn){
			await axios.post('/api/user-side/saveLanguage', {
				language
			})
		}

		// Change route
		await router.push({
			params: { language },
			query: queryString.parse(location.search) 
		})

		location.reload()
	}, 500)
}